import * as React from "react";

import Layout from "../../components/Layout";
import Work from "../../components/Work";

export default class WorkIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
            {/* {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
          <div className="container">
            <div className="content">
              <Work />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

