import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from "react-helmet";
import WorkItem from "../components/WorkItem"
import TagFilter from "../components/TagFilter"

class WorkTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedOption: "Show All"
    };
  }

  onChangeValue = (e) => {
    if (this.state.selectedOption === "Show All") {
      // just get regular data 
    } else {
      // call filter function
    }


    this.setState({selectedOption : e.target.value}, () => {
    
    });
  }


  // for all work if the frontmatter.tags includes this.state.selectionOption then return these results 


  render() {
    const { edges: works } = this.state.data.allMarkdownRemark
    return (
      <div className="work">
          <Helmet title={`Itza | Work`} />
          <TagFilter onChangeValue={this.onChangeValue} selectedOption={this.state.selectedOption}/>
          {this.state.selectedOption !== "Show All" ? works &&
          works.map(({ node: work }) => (
            work.frontmatter.title !== "digitals" && work.frontmatter.tags && work.frontmatter.tags.includes(this.state.selectedOption) &&
              <WorkItem key={work.id} work={work}/>
          )) : works &&
          works.map(({ node: work }) => (
            work.frontmatter.title !== "digitals" &&
              <WorkItem key={work.id} work={work}/>
          ))}
      </div>
    )
  }
}

Work.propTypes = {
  data: PropTypes.shape({ 
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}



export default function Work() {
  return (
    <StaticQuery
      query={graphql`
      query PhotoshootQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "work-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                date
                Photoshoot {
                  photos {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } 
      `}
      render={(data) => <WorkTemplate data={data} />}
    />
  );
}