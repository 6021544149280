import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const TagFilter = ({ onChangeValue, selectedOption }) => {
  const data = useStaticQuery(graphql`
    query TagsQuery {
      allMarkdownRemark(limit: 1000) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `)

  return (
        <div className="container content">
              <ul className="taglist">
                <li>
                  <input type="radio" name="category" value="Show All" checked={selectedOption === "Show All"} onChange={onChangeValue}/>
                  <label htmlFor="Show All">Show All</label>
                </li>
                {data.allMarkdownRemark.group.map((tag) => (
                  tag.fieldValue !== "digitals" &&
                  <li key={tag.fieldValue}>
                    <input type="radio" name="category" value={tag.fieldValue} checked={selectedOption === tag.fieldValue} onChange={onChangeValue}/>
                    <label htmlFor={tag.fieldValue}>{tag.fieldValue}</label>
                  </li>
                ))}
              </ul>
            </div>
  )
};


export default TagFilter;
