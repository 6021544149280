import * as React from "react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Carousel from "./Carousel"

const WorkItem = ({ work }) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    const openModal = () => {
      setShow(true);
    };

      const closeModal = () => {
        setShow(false)
    }
    
return (
    <>
        <div className="work-item">
            <div className={`${isHovering ? "item hovered" : "item"}`}>
                <button onClick={openModal} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onFocus={handleMouseOver} onBlur={handleMouseOut} className="work-btn">
                    <span>{work.frontmatter.title}</span>  
                </button>
                <span>{work.frontmatter.tags && work.frontmatter.tags.map((tag, index) => <span key={index}>{ (index ? ', ' : '') + tag }</span>)}</span>  
                {/* <span>{work.frontmatter.date.substring(0, 4)}</span>   */}
            </div>
            <div className={isHovering ? "work-show" : "work-hide"}>
                <PreviewCompatibleImage className="hovered-img" imageInfo={work.frontmatter.Photoshoot.photos[0]}/>
            </div>
        </div>
        {show && <Carousel photos={work.frontmatter.Photoshoot.photos} link={work.fields.slug} title={work.frontmatter.title} description={work.frontmatter.description} closeModal={closeModal}/>}
    </>
  );
}

export default WorkItem;
